import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';


// app services
import { AppConfigService } from './app-config.service';
import { LocalStorageService } from './localstorage.service';
import { EnvironmentService } from '../environment.service';
import { interval, Observable, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SessionExpiredConfirmationDialogComponent } from '../session/session-expired-confirmation-dialog/session-expired-confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  Claims: any;

  //session expiration time in ms, will be grabbed from db, default to 8 hrs
  sessionExpirationTime = 28800000;
  sessionTimer: Observable<number>;
  sessionTimerSub: Subscription;
  expirationDueTime: number;

  //Time in ms it will warn you before it expires (default to 10 min)
  warningToExpireTime: number = 600000;
  warningDialogRef: MatDialogRef<SessionExpiredConfirmationDialogComponent, any>

  constructor(
    private envSvc: EnvironmentService,
    private http: HttpClient,
    private lsSvc: LocalStorageService,
    private appConfig: AppConfigService,
    private router: Router,
    public dialog: MatDialog
  ) {
    const lsClaims = this.lsSvc.getItem('claims');
    if (lsClaims) {
      this.Claims = lsClaims;
    }
  }

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Cache-Control': 'no-cache',
      'Accept': 'application/json'
    })
  };

  /**
   * Check claim to see if user has access
   * @param code
   * @param verb
   */
  public hasClaim(programName: string, verb: any): boolean {

    const program: any = this.Claims[programName];

    if (!program) {
      return false;
    } else if (typeof verb === 'object') {
      let hc = false;
      verb.forEach((v: string) => {
        if (program[v]) {
          hc = true;
        }
      });

      return hc;

    } else if (!program[verb]) {
      return false;
    }

    return true;

  }

  /**
   * process login
   * @param params
   */
  public login(params: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache',
        'Accept': 'application/json'
      })
    };

    const payload = `grant_type=password&client_id=js&username=${params['username']}&password=${params['password']}&client_secret=${this.appConfig.clientSecret}`;

    return this.http.post(`${this.envSvc.endpoints.identity}/connect/token`, payload, httpOptions)
      .pipe(
        map((res: any) => {

          const token = res && res.access_token || null;
          const refreshToken = res.refresh_token || null;

          if (token) {
            this.lsSvc.setItem('user', { username: params['username'], token, refreshToken });
          
            this.setSessionExpirationTime(res?.expires_in);
            this.setSessionExpirationDue();

            return { success: true };
          } else {
            return { success: false };
          }

        })
      );
  }

  public refreshSession() {

    const user = this.lsSvc.getItem('user');

    if (!user) {
      document.location.href = '/';
    }

    const payload = `grant_type=refresh_token&client_id=js&client_secret=${this.appConfig.clientSecret}&refresh_token=${user.refresh_token}`;

    return this.http.post(`${this.envSvc.endpoints.identity}/connect/token`, payload, this._httpOptions)
      .pipe(
        map((res: any) => {

          const token = res && res.access_token || null;
          const refreshToken = res.refresh_token || null;

          this.lsSvc.setItem('user', { username: user['username'], token, refreshToken });

          return {
            success: token ? true : false
          }

        })
      );
  }

  /**
   * process logout
   */
  public logout(useReturnUrl: boolean): void {

    const user = this.lsSvc.getItem('user');

    if (user) {
      const payload = `token_type_hint=refresh_token&client_secret=${this.appConfig.clientSecret}&token=${user.refreshToken}&client_id=js`;

      this.http.post(`${this.envSvc.endpoints.identity}/connect/revocation`, payload, this._httpOptions)
        .subscribe(() => {
          this.clearAndRedirect(useReturnUrl);
        }, () => {
          this.clearAndRedirect(useReturnUrl);
        });

        //remove session expiration due time value from local storage
        this.lsSvc.removeItem('sessionExpiration');
    }
    else {
      this.redirect(useReturnUrl);
    }
  }

  clearAndRedirect(useReturnUrl: boolean) {
    this.lsSvc.removeItem('user');
    this.lsSvc.removeItem('upboardPerson');
    this.lsSvc.removeItem('user.upboardPerson');
    this.redirect(useReturnUrl);
  }

  redirect(useReturnUrl: boolean) {
    var queryString = '?ReturnUrl=' + encodeURIComponent(this.router.url);
    document.location.href = useReturnUrl ? queryString : "/";
  }

  getUserClaims() {

    const user = this.lsSvc.getItem('user');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
        'Authorization': `Bearer ${user['token']}`
      })
    };

    return this.http.get(`${this.envSvc.endpoints.identity}/api/AuthClaim/User`, httpOptions)
      .pipe(
        map((res: any) => {
          this.Claims = res.Claims;
          this.lsSvc.setItem('claims', res.Claims);
          user['firstName'] = res.FirstName;
          user['lastName'] = res.LastName;
          user['spid'] = res.SalesPersonId;
          user['companyId'] = res.CompanyId;
          user['storeRefId'] = res.StoreRefId;
          this.lsSvc.setItem('user', user);
          return { success: true, PromptForDefaultStore: res.PromptForDefaultStore };
        })
      );
  }

  /**
   * Clear local storage data that should only be accesible to authenitcated users
   */
  public clearData(): void {

    const items = ['user', 'cacheData', 'InvLookupSSF', 'InvLuSelectedId', 'pcCartId', 'claims', 'salesSystemConfig', 'showEnv'];
    items.forEach((item: string) => {
      this.lsSvc.removeItem(item);
    });

  }

  /**
   * Is user logged in
   * @returns {boolean}
   */
  public isLoggedIn(): boolean {

    const user = this.lsSvc.getItem('user');

    return user ? true : false;

  }

  // set expiration time that's return from identity, comes back in seconds
  private setSessionExpirationTime(expirationTime): void {

    // convert from seconds to miliseconds
    this.sessionExpirationTime = expirationTime * 1000;
    // if (expirationTime) {}
    // this.sessionExpirationTime = 630000;
  }

  private setSessionExpirationDue(): void {
    let expirationDue = Date.now() + this.sessionExpirationTime;

    // we store the expiration due in this service as well to compare with local storage
    // in case there was a log in in another tab
    this.expirationDueTime = expirationDue;
    this.lsSvc.setItem('sessionExpiration', expirationDue);
  }

  // only 1 instance of a timer per session
  //(1 instance per tab, but they will have the same due date)
  public startSessionTimer(): void {

    if (!this.sessionTimer) {
      let expirationDue = Number(this.lsSvc.getItem('sessionExpiration'));
      this.sessionTimer = interval(1000).pipe(map(() => {
        return expirationDue - Date.now();
      }));
    }
  }

  public getSessionTimer(): Observable<any> {
    return this.sessionTimer;
  }

  // call this to start and subscribe to a new timer for this session
  handleSessionExpiration() {

    if (!this.isLoggedIn()) {
      this.logout(true);
      return;
    }
   
    let pollingCount: number = 0;
    let warningOpen = false;
    this.sessionTimer = null;

    this.startSessionTimer();
    this.sessionTimerSub = this.getSessionTimer()
    .subscribe((ms) => {
      pollingCount += 1;

      // if timer reaches 0 automatically logout
      if (ms <= 0) {
        if (this.warningDialogRef) { this.warningDialogRef.close();}
        this.sessionTimerSub.unsubscribe();
      }

       // check every 30 seconds if due time has changed (if a login was done on another tab)
      if (pollingCount >= 30) {
        pollingCount = 0;
        let latestExpirationDue = Number(this.lsSvc.getItem('sessionExpiration'));
        if (latestExpirationDue !== this.expirationDueTime) {
          this.sessionTimerSub.unsubscribe();
          if (this.warningDialogRef) { this.warningDialogRef.close();}
          this.expirationDueTime = latestExpirationDue;
          this.handleSessionExpiration();
        }
      }

      else if ((ms <= this.warningToExpireTime && ms > 0) && !warningOpen) {
        warningOpen = true;
        this.openSessionExpirationWarning();
      }
      // Uncomment following line to monitor timer countdown in console
      // console.log(new Date(ms).toISOString().slice(11, 19))
    });
  }

  openSessionExpirationWarning() {
    this.warningDialogRef = this.dialog.open(SessionExpiredConfirmationDialogComponent, {
      position: {top: '60px'},
      width: '480px',
      autoFocus: false,
      restoreFocus: false,
      disableClose: true,
    });

    this.warningDialogRef.afterClosed().subscribe((res: any) => {
      if (res?.confirm) {
        this.logout(true);
      }
    });
  }
}
